import getConfig from 'next/config';
import Head from 'next/head';
import Script from 'next/script';
import { type ReactElement, useEffect, useState } from 'react';

import { brandConfig } from '@hubcms/brand';
import { useAccountConsent } from '@hubcms/data-access-account-consent';
import { useAuth } from '@hubcms/data-access-auth';
import { useCiamData } from '@hubcms/data-access-ciam';
import { useEnv } from '@hubcms/data-access-env';
import { isProdEnv } from '@hubcms/domain-env-config';
import { generateCacheBuster } from '@hubcms/utils-caching';

type TPianoLoaderProps = {
  articleId: string | null;
  isPremium: boolean | null;
  tagNames: string[];
  taxonomy: string[];
  zipCode: string | null;
};

const { publicRuntimeConfig } = getConfig();

function PianoScriptTag() {
  const auth0ClientId = publicRuntimeConfig.AUTH0_CLIENT_ID;
  const { MH_ENV } = useEnv() ?? {};
  const [hid, setHid] = useState<string | null>('loading');
  const cacheBuster = generateCacheBuster(new Date());

  const cxsenseSrc = isProdEnv(MH_ENV)
    ? `https://shared.mediahuis.be/cxense/cxense.${brandConfig.b2bUrlBrandCode}.min.js?v=${cacheBuster}`
    : `https://previewshared.mediahuis.be/cxense/cxense.${brandConfig.b2bUrlBrandCode}.min.js?v=${cacheBuster}`;
  const { isLoading: isUserLoading, user } = useAuth(); // used for compatibility with oauth
  const { getAccessToken } = useCiamData(auth0ClientId);
  const accessToken = auth0ClientId ? getAccessToken() : null;
  const { isAccountConsentLoaded, accountConsentStatus } = useAccountConsent(user?.sub ?? null, accessToken);

  useEffect(() => {
    if (!isUserLoading && isAccountConsentLoaded && hid === 'loading') {
      setHid(accountConsentStatus === 'Accepted' && user?.emailHash ? user?.emailHash : null);
    }
  }, [isUserLoading, isAccountConsentLoaded, hid, user?.emailHash, accountConsentStatus]);

  return (
    !isUserLoading &&
    hid !== 'loading' && <Script strategy="afterInteractive" src={cxsenseSrc} data-uid={user?.sub} data-hid={hid} />
  );
}
export const PianoLoader = ({
  articleId = null,
  isPremium = null,
  tagNames = [],
  taxonomy = [],
  zipCode = null,
}: Partial<TPianoLoaderProps>): ReactElement | null => {
  if (!brandConfig.b2bDMPMetaTagBrandCode) {
    return null;
  }

  const metaNamePrefix = `cXenseParse:${brandConfig.b2bDMPMetaTagBrandCode}`;

  return (
    <>
      <Head>
        <meta name={`${metaNamePrefix}-domain`} content={brandConfig.b2bUrlBrandCode} />
        {articleId && <meta name={`${metaNamePrefix}-articleid`} content={articleId} />}
        {isPremium !== null && <meta name={`${metaNamePrefix}-article_ispaidcontent`} content={String(isPremium)} />}
        {taxonomy.map(taxonomySlug => (
          <meta
            key={`taxonomy-${taxonomySlug}`}
            name="cXenseParse:Taxonomy"
            content={`${brandConfig.b2bUrlBrandCode}${taxonomySlug}`}
          />
        ))}
        {tagNames.map((tagName, tagIndex) => (
          <meta
            key={`tag-${tagName}`}
            name={`${metaNamePrefix}-${tagIndex === 0 ? 'article_maintag' : 'article_tag'}`}
            content={tagName}
          />
        ))}
        {zipCode && <meta name={`${metaNamePrefix}-page_zipcode`} content={zipCode} />}
      </Head>
      <PianoScriptTag />
    </>
  );
};
