import { useRouter } from 'next/router';
import Script from 'next/script';
import { type PropsWithChildren, type ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { brandConfig } from '@hubcms/brand';
import { useAuth } from '@hubcms/data-access-auth';
import { useEnv } from '@hubcms/data-access-env';
import { useNavigation } from '@hubcms/data-access-navigation';
import { useZephrData } from '@hubcms/data-access-zephr';
import type { KaChingInitData, SlotOptions } from '@hubcms/domain-ads';
import type { ApiIdentity } from '@hubcms/domain-auth';
import { isProdEnv } from '@hubcms/domain-env-config';
import type { SubscriberInfo } from '@hubcms/domain-zephr';
import { generateCacheBuster } from '@hubcms/utils-caching';
import { error } from '@hubcms/utils-monitoring';
import { getUserRegioSelection } from '@hubcms/utils-my-section';
import { getNavigationVersion } from '@hubcms/utils-navigation';
import { Deferred } from '@hubcms/utils-promise';

import type { KaChing } from '../../../domain/kaChing';

import { KaChingContext, type RegisterAdFn, type UnRegisterAdFn } from './KaChingContext';
import { createAdKey } from './createAdKey';

declare global {
  interface Window {
    didomiOnReady?: unknown[];
    KaChing: KaChing;
  }
}

const kachingDisabled = process.env['NEXT_PUBLIC_DISABLE_KACHING'] === 'true';

const didomiPromise = new Promise(function (resolve) {
  if (typeof window !== 'undefined') {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(resolve);
  }
});

const kachingDeferred = new Deferred();
type KaChingProviderProps = PropsWithChildren<{
  initData: KaChingInitData;
}>;

export function KaChingProvider({ initData, children }: KaChingProviderProps): ReactElement {
  const { MH_ENV } = useEnv() ?? {};
  const cacheBuster = generateCacheBuster(new Date());
  const [isFinished, setIsFinished] = useState(kachingDisabled);
  const kachingSrc = isProdEnv(MH_ENV)
    ? `https://shared.mediahuis.be/ka-ching/${brandConfig.b2bUrlBrandCode}/ka-ching.umd.js?v=${cacheBuster}`
    : `https://previewshared.mediahuis.be/ka-ching/${brandConfig.b2bUrlBrandCode}/ka-ching.umd.dev.js?v=${cacheBuster}`;
  const router = useRouter();
  const [kachingInstance, setKachingInstance] = useState<typeof window.KaChing | null>(null);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const kachingSlotsRef = useRef<Record<string, [string, string, SlotOptions]>>({});
  const adFormatCounter = useRef<Record<string, string[]>>({});
  const zipCode = getUserRegioSelection()?.communityIdentifier;
  const { placementGroup } = initData;
  const { user, isLoading: isAuthLoading } = useAuth();
  const { subscriber, hasOutcomes } = useZephrData();
  const { advertisement: navigationAd } = useNavigation();
  const navigationVersion = getNavigationVersion();

  const registerAd: RegisterAdFn = useCallback(
    (adFormat, adSlot, options = {}) => {
      kachingSlotsRef.current = {
        ...kachingSlotsRef.current,
        [createAdKey(adFormat, adSlot)]: [adFormat, adSlot, options],
      };
      if (kachingInstance?.isInitialRequestFired) {
        try {
          // eslint-disable-next-line max-depth
          if (brandConfig.isKachingNewAdKeyFormat && kachingInstance?.definePlacement) {
            kachingInstance?.definePlacement({ id: createAdKey(adFormat, adSlot) });
          } else {
            kachingInstance?.defineSlot(adFormat, adSlot, options);
          }
          kachingInstance?.init();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
      }
    },
    [kachingSlotsRef, kachingInstance],
  );

  const unregisterAd: UnRegisterAdFn = useCallback(
    (adFormat, adSlot) => {
      const clone = { ...kachingSlotsRef.current };

      delete clone[createAdKey(adFormat, adSlot)];
      kachingSlotsRef.current = clone;
      if (kachingInstance?.isInitialRequestFired) {
        kachingInstance?.destroy(`${createAdKey(adFormat, adSlot)}`);
      }
    },
    [kachingSlotsRef, kachingInstance],
  );

  const getLoggedInStatus = (subscriber: SubscriberInfo | null, user: ApiIdentity['user'] | null) => {
    if (user?.sub && subscriber) {
      const isSubscriber = Array.isArray(subscriber?.allowedProducts) ? subscriber.allowedProducts.length > 0 : false;
      return isSubscriber ? '2' : '1';
    }
    return '0';
  };

  useEffect(() => {
    const initializeKaChing = async () => {
      if (navigationAd && navigationVersion === '2') {
        registerAd(navigationAd.format, navigationAd.slot, navigationAd.options);
      }

      const inIFrame = window.self !== window.top;
      setIsPreview(inIFrame);
      if (!kachingDisabled && !inIFrame && !isAuthLoading) {
        await didomiPromise;
        await kachingDeferred.promise;

        await kachingInstance?.reset();

        const slots = Object.values(kachingSlotsRef.current);
        slots.forEach(([adFormat, adSlot, options]) => {
          try {
            if (brandConfig.isKachingNewAdKeyFormat && kachingInstance?.definePlacement) {
              kachingInstance?.definePlacement({ id: createAdKey(adFormat, adSlot) });
            } else {
              kachingInstance?.defineSlot(adFormat, adSlot, options);
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
          }
        });
        initData.platform = window.innerWidth > 728 ? 'WWW' : 'MOB';
        if (zipCode) {
          // eslint-disable-next-line camelcase
          initData.targeting.page_zipcode = zipCode;
        }

        if (user?.sub) {
          const prefix = brandConfig.authMethod === 'ciamAuth0' ? 'auth0' : 'guid';
          initData.guid = { prefix, value: user.sub };
        }

        // eslint-disable-next-line camelcase
        initData.targeting.logged_in = getLoggedInStatus(subscriber, user);
        initData.targeting.paywall = `${hasOutcomes}`;

        kachingInstance?.init(initData);
      }
    };

    initializeKaChing();

    return () => {
      if (navigationAd) {
        unregisterAd(navigationAd.format, navigationAd.slot);
      }
    };
  }, [
    router.asPath,
    initData,
    kachingInstance,
    zipCode,
    user,
    isAuthLoading,
    subscriber,
    hasOutcomes,
    navigationAd,
    registerAd,
    unregisterAd,
    navigationVersion,
  ]);

  return (
    <>
      <KaChingContext.Provider
        value={{
          registerAd,
          unregisterAd,
          kachingInstance,
          adFormatCounter: adFormatCounter.current,
          isPreview,
          kachingInitData: initData,
          isFinished,
        }}
      >
        {children}
      </KaChingContext.Provider>
      {!kachingDisabled && (
        <Script
          id="kaching"
          src={kachingSrc}
          strategy="beforeInteractive"
          onReady={() => {
            try {
              kachingDeferred.resolve?.();
              setIsFinished(true);
              setKachingInstance(placementGroup ? new window.KaChing({ placementGroup }) : new window.KaChing());
            } catch (err) {
              setIsFinished(true);
              error(err);
            }
          }}
        />
      )}
    </>
  );
}
