import Script from 'next/script';
import type { ReactElement } from 'react';

import { brandConfig } from '@hubcms/brand';
import type { SectionParams } from '@hubcms/domain-cook';

import { isValidCimIdentifier } from '../utils/isValidCimIdentifier';

export function CimTracker({ sectionParams }: { sectionParams?: SectionParams }): ReactElement | null {
  const identifier = sectionParams?.['analytics.CIM.identifier'] ?? brandConfig.cimIdentifier;

  if (!identifier || !isValidCimIdentifier(identifier)) return null;

  const lang = brandConfig.cimLanguage;
  const subs = sectionParams?.['analytics.CIM.subsection'] ?? null;

  const trackerScript = `var pp_gemius_identifier = '${identifier}';
var pp_gemius_use_cmp = true;
var pp_gemius_cmp_purposes = {2:[1,8,9,10]};
var pp_gemius_cmp_timeout = Infinity;
var pp_gemius_extraparameters = ${JSON.stringify([`lan=${lang}`, subs ? `subs=${subs}` : false].filter(i => i))};
// lines below shouldn't be edited
function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || [];
x[x.length]=arguments;};};gemius_pending('gemius_hit');gemius_pending('gemius_event');
gemius_pending('gemius_init');gemius_pending('pp_gemius_hit');
gemius_pending('pp_gemius_event'); gemius_pending('pp_gemius_init');
(function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],
l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');gt.setAttribute('defer','defer');
gt.src=l+'://galu.hit.gemius.pl/gemius.js'; s.parentNode.insertBefore(gt,s);} catch (e){}})(document,'script');`;
  return <Script id="cim" dangerouslySetInnerHTML={{ __html: trackerScript }} />;
}
